const RESET_USERS_ERR = 'RESET_USERS_ERR';

const RESET_USER_DATA = 'RESET_USER_DATA';

const GET_USERS_TYPES = 'GET_USERS_TYPES';
const GET_USERS_TYPES_SUCCESS = 'GET_USERS_TYPES_SUCCESS';
const GET_USERS_TYPES_FAIL = 'GET_USERS_TYPES_FAIL';

const GET_USERS = 'GET_USERS';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_FAIL = 'GET_USERS_FAIL';

const GET_USER = 'GET_USER';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_FAIL = 'GET_USER_FAIL';

const REMOVE_USER = 'REMOVE_USER';
const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
const REMOVE_USER_FAIL = 'REMOVE_USER_FAIL';

const UPDATE_USER = 'UPDATE_USER';
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

const ADD_USER = 'ADD_USER';
const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
const ADD_USER_FAIL = 'ADD_USER_FAIL';

const SEND_SUPPORT_EMAIL = 'SEND_SUPPORT_EMAIL';
const SEND_SUPPORT_EMAIL_SUCCESS = 'SEND_SUPPORT_EMAIL_SUCCESS';
const SEND_SUPPORT_EMAIL_FAIL = 'SEND_SUPPORT_EMAIL_FAIL';

const initialState = {
  users: [],
  user: {},
  usersError: {},
  usersTypes: [],
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_TYPES:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        usersTypes: action.result.usersTypes.map(s => ({
          value: s.id,
          label: s.title,
        })),
      };
    case GET_USERS_TYPES_FAIL:
      return {
        ...state,
        isErrorVisible: true,
        loading: false,
        usersError: action.error,
      };

    case RESET_USERS_ERR:
      return {
        ...state,
        authError: {},
      };

    case GET_USERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.result.users,
        count: action.result.count,
        loading: false,
      };
    }
    case GET_USERS_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.result.user,
        loading: false,
      };
    }
    case GET_USER_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case UPDATE_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_USER_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case REMOVE_USER:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case RESET_USER_DATA:
      return {
        ...state,
        user: {},
        error: {},
      };

    case ADD_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_USER_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case SEND_SUPPORT_EMAIL: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEND_SUPPORT_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEND_SUPPORT_EMAIL_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    default:
      return state;
  }
}

export function getUsersTypes() {
  return {
    types: [GET_USERS_TYPES, GET_USERS_TYPES_SUCCESS, GET_USERS_TYPES_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/users/types`),
  };
}

export function resetUsersErr() {
  return {
    type: RESET_USERS_ERR,
  };
}

export function getUsers(data) {
  const { searchTerm } = data;
  return {
    types: [GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/users?search=${searchTerm}`
      ),
  };
}

export function getUser(userId) {
  return {
    types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/users/${userId}`),
  };
}

export function removeUser(userId) {
  return {
    types: [REMOVE_USER, REMOVE_USER_SUCCESS, REMOVE_USER_FAIL],
    promise: client =>
      client.del(`${process.env.TOTOTHEO_API_ADMIN_URL}/users/${userId}`),
  };
}

export function resetUserData() {
  return {
    type: RESET_USER_DATA,
  };
}

export function updateUser(data, userId) {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL],
    promise: client =>
      client.put(`${process.env.TOTOTHEO_API_ADMIN_URL}/users/${userId}`, {
        data,
      }),
  };
}

export function addUser(data) {
  return {
    types: [ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAIL],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_ADMIN_URL}/users`, { data }),
  };
}

export function sendSupportEmail(data) {
  return {
    types: [
      SEND_SUPPORT_EMAIL,
      SEND_SUPPORT_EMAIL_SUCCESS,
      SEND_SUPPORT_EMAIL_FAIL,
    ],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_ADMIN_URL}/admin/support`, {
        data,
      }),
  };
}
