import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as alertBarActions from '../../redux/modules/alerts';

import { Snackbar, IconButton } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

export default function Alerts() {
  const text = useSelector(state => state.alert.text);
  const isVisible = useSelector(state => state.alert.isVisible);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleRequestClose = useCallback(() => {
    setOpen(false);
    dispatch(alertBarActions.hideValidationAlert());
    dispatch(alertBarActions.hideSuccessAlert());
  }, [dispatch]);

  useEffect(() => {
    if (text) {
      setOpen(true);
      setTimeout(
        () => {
          handleRequestClose();
        },
        text.length < 50 ? 3000 : 6000
      );
    }
  }, [text, handleRequestClose]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={`snackbar ${isVisible ? 'danger' : 'success'}`}
        open={open}
        message={
          <span className="snackbarMassage">
            {isVisible ? <ErrorIcon /> : <CheckCircleIcon />}
            {text}
          </span>
        }
        action={
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        }
      />
    </div>
  );
}
