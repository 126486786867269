import { combineReducers } from 'redux';

import spinner from './spinner';
import auth from './auth';
import ships from './ships';
import alert from './alerts';
import company from './company';
import users from './users';
import sensors from './sensors';
import dashboard from './dashboard';

export default function() {
  const appReducer = combineReducers({
    auth: auth(),
    ships: ships(),
    dashboard: dashboard(),
    alert,
    spinner,
    company,
    users,
    sensors,
  });

  return (state, action) => appReducer(state, action);
}
