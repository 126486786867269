import moment from 'moment';

const RESET_SHIPS_ERR = 'RESET_SHIPS_ERR';

const RESET_SHIP_DATA = 'RESET_SHIP_DATA';

const SET_SHIP_IN_FORM = 'SET_SHIP_IN_FORM';

const SET_SHIP_FORM_DATA = 'SET_SHIP_FORM_DATA';
const RESET_SHIP_FORM_DATA = 'RESET_SHIP_FORM_DATA';

const GET_SHIPS_TYPES = 'GET_SHIPS_TYPES';
const GET_SHIPS_TYPES_SUCCESS = 'GET_SHIPS_TYPES_SUCCESS';
const GET_SHIPS_TYPES_FAIL = 'GET_SHIPS_TYPES_FAIL';

const GET_SHIPS = 'GET_SHIPS';
const GET_SHIPS_SUCCESS = 'GET_SHIPS_SUCCESS';
const GET_SHIPS_FAIL = 'GET_SHIPS_FAIL';

const GET_SHIP = 'GET_SHIP';
const GET_SHIP_SUCCESS = 'GET_SHIP_SUCCESS';
const GET_SHIP_FAIL = 'GET_SHIP_FAIL';

const ADD_SHIP = 'ADD_SHIP';
const ADD_SHIP_SUCCESS = 'ADD_SHIP_SUCCESS';
const ADD_SHIP_FAIL = 'ADD_SHIP_FAIL';

const UPDATE_SHIP = 'UPDATE_SHIP';
const UPDATE_SHIP_SUCCESS = 'UPDATE_SHIP_SUCCESS';
const UPDATE_SHIP_FAIL = 'UPDATE_SHIP_FAIL';

const REMOVE_SHIP = 'REMOVE_SHIP';
const REMOVE_SHIP_SUCCESS = 'REMOVE_SHIP_SUCCESS';
const REMOVE_SHIP_FAIL = 'REMOVE_SHIP_FAIL';

const UPLOAD_FILE = 'UPLOAD_FILE';
const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

const REMOVE_FILE = 'REMOVE_FILE';
const REMOVE_FILE_SUCCESS = 'REMOVE_FILE_SUCCESS';
const REMOVE_FILE_FAIL = 'REMOVE_FILE_FAIL';

const SEARCH_SHIPS = 'SEARCH_SHIPS';
const SEARCH_SHIPS_SUCCESS = 'SEARCH_SHIPS_SUCCESS';
const SEARCH_SHIPS_FAIL = 'SEARCH_SHIPS_FAIL';

const initialState = {
  shipsError: {},
  shipFileUrl: null,
  currentShipId: null,
  shipsTypes: [],
  ships: [],
  ship: {},
  loading: false,
  currentSensorsShip: null,
  shipFormData: {},
};

export default function reducer() {
  return function(state = initialState, action = {}) {
    switch (action.type) {
      case GET_SHIPS_TYPES: {
        return {
          ...state,
          loading: true,
        };
      }
      case GET_SHIPS_TYPES_SUCCESS: {
        return {
          ...state,
          loading: false,
          shipsTypes: action.result.types.map(s => ({
            value: s.id,
            label: s.title,
          })),
        };
      }
      case GET_SHIPS_TYPES_FAIL:
        return {
          ...state,
          isErrorVisible: true,
          loading: false,
          shipsError: action.error,
        };

      case GET_SHIPS:
        return {
          ...state,
          loading: true,
        };
      case GET_SHIPS_SUCCESS:
        return {
          ...state,
          loading: false,
          ships: action.result.ships.map(s => ({
            ...s,
            createdAt: moment(s.createdAt).format('lll'),
            updatedAt: moment(s.updatedAt).format('lll'),
          })),
        };
      case GET_SHIPS_FAIL:
        return {
          ...state,
          isErrorVisible: true,
          loading: false,
          shipsError: action.error,
        };

      case GET_SHIP:
        return {
          ...state,
          loading: true,
        };
      case GET_SHIP_SUCCESS:
        return {
          ...state,
          loading: false,
          ship: action.result.ship,
        };
      case GET_SHIP_FAIL:
        return {
          ...state,
          isErrorVisible: true,
          loading: false,
          shipsError: action.error,
        };

      case ADD_SHIP:
        return {
          ...state,
          loading: true,
        };

      case ADD_SHIP_SUCCESS:
        return {
          ...state,
          loading: false,
        };

      case ADD_SHIP_FAIL:
        return {
          ...state,
          loading: false,
          shipsError: action.error,
        };

      case UPDATE_SHIP:
        return {
          ...state,
          loading: true,
        };

      case UPDATE_SHIP_SUCCESS:
        return {
          ...state,
          loading: false,
          ship: action.result.ship,
        };

      case UPDATE_SHIP_FAIL:
        return {
          ...state,
          loading: false,
          shipsError: action.error,
        };

      case REMOVE_SHIP:
        return {
          ...state,
          loading: true,
        };

      case REMOVE_SHIP_SUCCESS:
        const shipIdForDelete = action.result.shipId;
        let currentSensorsShip = state.currentSensorsShip;
        if (
          currentSensorsShip &&
          shipIdForDelete === currentSensorsShip.value
        ) {
          currentSensorsShip = null;
        }
        return {
          ...state,
          loading: false,
          currentSensorsShip: currentSensorsShip,
        };

      case REMOVE_SHIP_FAIL:
        return {
          ...state,
          loading: false,
          shipsError: action.error,
        };

      case UPLOAD_FILE:
        return {
          ...state,
          loading: true,
        };

      case UPLOAD_FILE_SUCCESS:
        return {
          ...state,
          loading: false,
        };

      case UPLOAD_FILE_FAIL:
        return {
          ...state,
          isErrorVisible: true,
          loading: false,
          shipsError: action.error,
        };

      case REMOVE_FILE:
        return {
          ...state,
          loading: true,
        };

      case REMOVE_FILE_SUCCESS:
        return {
          ...state,
          loading: false,
        };

      case REMOVE_FILE_FAIL:
        return {
          ...state,
          isErrorVisible: true,
          loading: false,
          shipsError: action.error,
        };

      case SEARCH_SHIPS:
        return {
          ...state,
        };
      case SEARCH_SHIPS_SUCCESS:
        return {
          ...state,
          shipsSearchList: [...action.result.ships],
          count: action.result.count,
        };
      case SEARCH_SHIPS_FAIL:
        return {
          ...state,
        };

      case RESET_SHIP_DATA:
        return {
          ...state,
          ship: {},
          shipsError: {},
        };

      case RESET_SHIPS_ERR:
        return {
          ...state,
          shipsError: {},
        };
      case SET_SHIP_IN_FORM:
        return {
          ...state,
          currentSensorsShip: action.currentSensorsShip,
        };
      case SET_SHIP_FORM_DATA:
        return {
          ...state,
          shipFormData: {
            formData: action.data,
            usersIdsToDelete: action.usersIdsToDelete,
            decksIdsToDelete: action.decksIdsToDelete,
            companyId: action.companyId,
            shipId: action.shipId,
          },
        };
      case RESET_SHIP_FORM_DATA:
        return {
          ...state,
          shipFormData: {},
        };
      default:
        return state;
    }
  };
}

export function getShipsTypes() {
  return {
    types: [GET_SHIPS_TYPES, GET_SHIPS_TYPES_SUCCESS, GET_SHIPS_TYPES_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/ships/types`),
  };
}

export function getShips() {
  return {
    types: [GET_SHIPS, GET_SHIPS_SUCCESS, GET_SHIPS_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/ships`),
  };
}

export function getShip(shipId) {
  return {
    types: [GET_SHIP, GET_SHIP_SUCCESS, GET_SHIP_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/ships/${shipId}`),
  };
}

export function addShip(data) {
  return {
    types: [ADD_SHIP, ADD_SHIP_SUCCESS, ADD_SHIP_FAIL],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_ADMIN_URL}/ships`, {
        data,
      }),
  };
}

export function updateShip(data, shipId) {
  return {
    types: [UPDATE_SHIP, UPDATE_SHIP_SUCCESS, UPDATE_SHIP_FAIL],
    promise: client =>
      client.put(`${process.env.TOTOTHEO_API_ADMIN_URL}/ships/${shipId}`, {
        data,
      }),
  };
}

export function removeShip(shipId) {
  return {
    types: [REMOVE_SHIP, REMOVE_SHIP_SUCCESS, REMOVE_SHIP_FAIL],
    promise: client =>
      client.del(`${process.env.TOTOTHEO_API_ADMIN_URL}/ships/${shipId}`),
  };
}

export function uploadFile(file, type) {
  return {
    types: [UPLOAD_FILE, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAIL],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_ADMIN_URL}/ships/file/${type}`, {
        attach: file,
      }),
  };
}

export function removeFile(url, fieldName) {
  const file = { fileUrl: url, fieldName };
  return {
    types: [REMOVE_FILE, REMOVE_FILE_SUCCESS, REMOVE_FILE_FAIL],
    promise: client =>
      client.del(`${process.env.TOTOTHEO_API_ADMIN_URL}/ships/file`, {
        data: {
          file,
        },
      }),
  };
}

export function searchShips(data) {
  const { limit, offset, search } = data;

  return {
    types: [SEARCH_SHIPS, SEARCH_SHIPS_SUCCESS, SEARCH_SHIPS_FAIL],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/ships?limit=${limit}&offset=${offset}&search=${search}`
      ),
  };
}

export function resetShipData() {
  return {
    type: RESET_SHIP_DATA,
  };
}

export function resetShipsErr() {
  return {
    type: RESET_SHIPS_ERR,
  };
}

export function setShipInForm(ship) {
  return {
    type: SET_SHIP_IN_FORM,
    currentSensorsShip: ship,
  };
}

export function setShipFormData(
  data,
  usersIdsToDelete,
  decksIdsToDelete,
  companyId,
  shipId
) {
  return {
    type: SET_SHIP_FORM_DATA,
    data,
    usersIdsToDelete,
    decksIdsToDelete,
    companyId,
    shipId,
  };
}
export function resetShipFormData() {
  return {
    type: RESET_SHIP_FORM_DATA,
  };
}
