import Cookies from 'js-cookie';
import humps from 'humps';
import base64 from '../../helpers/base64';

const RESET_AUTH_ERR = 'RESET_AUTH_ERR';

const LOGOUT = 'LOGOUT';

const SIGN_IN = 'SIGN_IN';
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

const CHANGE_CURRENT_COMPANY = 'CHANGE_CURRENT_COMPANY';

const UPDATE_PROFILE = 'UPDATE_PROFILE';
const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

const GET_CURRENT_COMPANY_DATA = 'GET_CURRENT_COMPANY_DATA';
const GET_CURRENT_COMPANY_DATA_SUCCESS = 'GET_CURRENT_COMPANY_DATA_SUCCESS';
const GET_CURRENT_COMPANY_DATA_FAIL = 'GET_CURRENT_COMPANY_DATA_FAIL';

const DELETE_CURRENT_COMPANY_DATA = 'DELETE_CURRENT_COMPANY_DATA';

const GET_PROFILE = 'GET_PROFILE';
const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

const initialState = {
  authError: {},
  user: {},
  company: {},
  login: false,
  loading: false,
};

export default function reducer() {
  let cookieOptions = {
    domain: process.env.DOMAIN_NAME || window.location.hostname,
    expires: 7,
  };
  try {
    initialState.user = JSON.parse(Cookies.get('tototheoAdmin') || '{}');
    initialState.company = JSON.parse(Cookies.get('tototheoCompany') || '{}');
  } catch (error) {}

  if (initialState.user.token) {
    initialState.user = humps.camelizeKeys({
      ...base64.decodeToken(initialState.user.token),
      ...initialState.user,
    });
    initialState.login = true;
  }
  return function(state = initialState, action = {}) {
    switch (action.type) {
      case SIGN_IN: {
        return {
          ...state,
          login: false,
          loading: true,
        };
      }

      case SIGN_IN_SUCCESS: {
        const { company, ...rest } = action.result;

        Cookies.set('tototheoAdmin', JSON.stringify(rest), {
          ...cookieOptions,
        });
        company &&
          Cookies.set('tototheoCompany', JSON.stringify(company), {
            ...cookieOptions,
          });

        return {
          ...state,
          login: true,
          user: {
            ...humps.camelizeKeys(base64.decodeToken(action.result.token)),
            token: action.result.token,
          },
          company: company || {},
        };
      }

      case SIGN_IN_FAIL:
        return {
          ...state,
          login: false,
          loading: false,
          isErrorVisible: true,
          authError: action.error,
        };

      case RESET_AUTH_ERR:
        return {
          ...state,
          authError: {},
        };

      case LOGOUT:
        Cookies.remove('tototheoAdmin', { ...cookieOptions });
        Cookies.remove('tototheoCompany', { ...cookieOptions });
        return {
          ...initialState,
          user: {},
          company: {},
          login: false,
          logout: true,
        };

      case CHANGE_CURRENT_COMPANY:
        const { company } = action;
        Cookies.set('tototheoCompany', JSON.stringify(company), {
          ...cookieOptions,
        });
        return {
          ...state,
          company,
        };

      case UPDATE_PROFILE:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_PROFILE_SUCCESS:
        const { result } = action;
        Cookies.set('tototheoAdmin', JSON.stringify(result), {
          ...cookieOptions,
        });
        return {
          ...state,
          user: {
            ...humps.camelizeKeys(base64.decodeToken(result.token)),
            token: result.token,
          },
          loading: false,
        };
      case UPDATE_PROFILE_FAIL:
        return {
          ...state,
          loading: false,
        };

      case GET_PROFILE:
        return {
          ...state,
          loading: true,
        };
      case GET_PROFILE_SUCCESS:
        Cookies.set('tototheoAdmin', JSON.stringify(action.result), {
          ...cookieOptions,
        });
        return {
          ...state,
          user: {
            ...humps.camelizeKeys(base64.decodeToken(action.result.token)),
            token: action.result.token,
          },
          loading: false,
        };
      case GET_PROFILE_FAIL:
        Cookies.remove('tototheoAdmin', { ...cookieOptions });
        Cookies.remove('tototheoCompany', { ...cookieOptions });
        return {
          ...initialState,
          user: {},
          company: {},
          login: false,
          logout: true,
        };

      case GET_CURRENT_COMPANY_DATA:
        return {
          ...state,
          loading: true,
        };
      case GET_CURRENT_COMPANY_DATA_SUCCESS:
        Cookies.set('tototheoCompany', JSON.stringify(action.result.company), {
          ...cookieOptions,
        });
        return {
          ...state,
          loading: false,
          company: action.result.company,
        };
      case GET_CURRENT_COMPANY_DATA_FAIL:
        return {
          ...state,
          isErrorVisible: true,
          loading: false,
          shipsError: action.error,
        };
      case DELETE_CURRENT_COMPANY_DATA:
        Cookies.remove('tototheoCompany', { ...cookieOptions });
        return {
          ...state,
          company: {},
        };

      default:
        return state;
    }
  };
}

export function signIn(data) {
  return {
    types: [SIGN_IN, SIGN_IN_SUCCESS, SIGN_IN_FAIL],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_ADMIN_URL}/auth/signin`, {
        data,
      }),
  };
}

export function changeCurrentCompany(company) {
  return {
    type: CHANGE_CURRENT_COMPANY,
    company,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function resetAuthErr() {
  return {
    type: RESET_AUTH_ERR,
  };
}

export function updateProfile(data) {
  return {
    types: [UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_ADMIN_URL}/profile/update`, {
        data,
      }),
  };
}

export function getProfile() {
  return {
    types: [GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/profile`),
  };
}

export function getCurrentCompanyData(companyId) {
  return {
    types: [
      GET_CURRENT_COMPANY_DATA,
      GET_CURRENT_COMPANY_DATA_SUCCESS,
      GET_CURRENT_COMPANY_DATA_FAIL,
    ],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/companies/${companyId}`
      ),
  };
}

export function deleteCurentCompanyData() {
  return {
    type: DELETE_CURRENT_COMPANY_DATA,
  };
}
