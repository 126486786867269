const SPINNER_VISIBLE = 'SPINNER_VISIBLE';
const SPINNER_INVISIBLE = 'SPINNER_INVISIBLE';
const SPINNER_DID_MOUNT = 'SPINNER_DID_MOUNT';

const initialState = {
  isVisible: false,
  isDidMount: false,
  visibleCallCount: 0,
  invisibleCallCount: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SPINNER_VISIBLE:
      return {
        ...state,
        isVisible: true,
        visibleCallCount: state.visibleCallCount + 1,
      };

    case SPINNER_INVISIBLE:
      return {
        ...state,
        isVisible: !(state.invisibleCallCount === state.visibleCallCount - 1),
        invisibleCallCount: state.invisibleCallCount + 1,
      };

    case SPINNER_DID_MOUNT:
      return {
        ...state,
        isDidMount: true,
      };

    default:
      return state;
  }
}

export function showSpinner() {
  return {
    type: SPINNER_VISIBLE,
  };
}

export function hideSpinner() {
  return {
    type: SPINNER_INVISIBLE,
  };
}

export function didMountSpinner() {
  return {
    type: SPINNER_DID_MOUNT,
  };
}
