import { createStore, applyMiddleware, compose } from 'redux';
import createMiddleware from './middleware/request';
import { createLogger } from 'redux-logger';
import reducer from '../redux/modules/reducer';

export default function getStore(api) {
  let enhancers = [];
  let middleware = [createMiddleware(api)];

  if (process.env.__DEVTOOLS__) {
    middleware.push(createLogger());
  }

  enhancers.push(applyMiddleware(...middleware));

  return createStore(reducer(), undefined, compose(...enhancers));
}
