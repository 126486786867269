import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as spinnerActions from '../redux/modules/spinner';
import Alerts from '../components/alerts/Alerts';

export default function Spinner() {
  const isDidMount = useSelector(state => state.spinner.isDidMount);
  const isVisible = useSelector(state => state.spinner.isVisible);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(spinnerActions.didMountSpinner());
  }, []);

  const htmlSpinner = () => (
    <>
      {isVisible && isDidMount && (
        <div className="htmlSpinner">
          <div className="logo slide-in-left" />
          <div className="loadingio-spinner-ellipsis">
            <div className="ldio">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <React.Fragment>
      {htmlSpinner()}
      <Alerts />
    </React.Fragment>
  );
}
