const GET_SENSORS_TYPES = 'GET_SENSORS_TYPES';
const GET_SENSORS_TYPES_SUCCESS = 'GET_SENSORS_TYPES_SUCCESS';
const GET_SENSORS_TYPES_FAIL = 'GET_SENSORS_TYPES_FAIL';

const GET_SENSORS = 'GET_SENSORS';
const GET_SENSORS_SUCCESS = 'GET_SENSORS_SUCCESS';
const GET_SENSORS_FAIL = 'GET_SENSORS_FAIL';

const GET_SENSOR = 'GET_SENSOR';
const GET_SENSOR_SUCCESS = 'GET_SENSOR_SUCCESS';
const GET_SENSOR_FAIL = 'GET_SENSOR_FAIL';

const GET_SHIPS_WITH_DECKS = 'GET_SHIPS_WITH_DECKS';
const GET_SHIPS_WITH_DECKS_SUCCESS = 'GET_SHIPS_WITH_DECKS_SUCCESS';
const GET_SHIPS_WITH_DECKS_FAIL = 'GET_SHIPS_WITH_DECKS_FAIL';

const ADD_SENSOR = 'ADD_SENSOR';
const ADD_SENSOR_SUCCESS = 'ADD_SENSOR_SUCCESS';
const ADD_SENSOR_FAIL = 'ADD_SENSOR_FAIL';

const EDIT_SENSOR = 'EDIT_SENSOR';
const EDIT_SENSOR_SUCCESS = 'EDIT_SENSOR_SUCCESS';
const EDIT_SENSOR_FAIL = 'EDIT_SENSOR_FAIL';

const GET_ALERT_DURATION_TYPES = 'GET_ALERT_DURATION_TYPES';
const GET_ALERT_DURATION_TYPES_SUCCESS = 'GET_ALERT_DURATION_TYPES_SUCCESS';
const GET_ALERT_DURATION_TYPES_FAIL = 'GET_ALERT_DURATION_TYPES_FAIL';

const initialState = {
  sensors: [],
  sensor: {},
  sensorsTypes: [],
  shipsWithDecks: [],
  sensorsError: {},
  loading: false,
  alertActiveDurationTypes: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SENSORS: {
      return {
        ...state,
      };
    }
    case GET_SENSORS_SUCCESS: {
      return {
        ...state,
        sensors: [...action.result.sensors],
      };
    }
    case GET_SENSORS_FAIL:
      return {
        ...state,
      };

    case GET_SENSOR: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SENSOR_SUCCESS: {
      return {
        ...state,
        sensor: action.result.sensor,
        loading: false,
      };
    }
    case GET_SENSOR_FAIL:
      return {
        ...state,
        loading: false,
        sensorsError: action.error,
      };

    case GET_SENSORS_TYPES: {
      return {
        ...state,
      };
    }
    case GET_SENSORS_TYPES_SUCCESS: {
      return {
        ...state,
        sensorsTypes: action.result.types.map(s => {
          let disabled = false;
          if (s.title === 'CAMERA') {
            disabled = true;
          }
          return {
            value: s.id,
            label: s.title,
            idDisabled: disabled,
          };
        }),
      };
    }
    case GET_SENSORS_TYPES_FAIL:
      return {
        ...state,
      };

    case GET_SHIPS_WITH_DECKS:
      return {
        ...state,
      };
    case GET_SHIPS_WITH_DECKS_SUCCESS:
      return {
        ...state,
        shipsWithDecks: action.result.ships,
      };
    case GET_SHIPS_WITH_DECKS_FAIL:
      return {
        ...state,
      };

    case ADD_SENSOR:
      return {
        ...state,
      };
    case ADD_SENSOR_SUCCESS:
      return {
        ...state,
      };
    case ADD_SENSOR_FAIL:
      return {
        ...state,
      };

    case EDIT_SENSOR:
      return {
        ...state,
      };
    case EDIT_SENSOR_SUCCESS:
      return {
        ...state,
      };
    case EDIT_SENSOR_FAIL:
      return {
        ...state,
      };

    case GET_ALERT_DURATION_TYPES:
      return {
        ...state,
      };
    case GET_ALERT_DURATION_TYPES_SUCCESS:
      return {
        ...state,
        alertActiveDurationTypes: action.result.alertTypes.map(elem => {
          return {
            value: elem.id,
            label: elem.title,
          };
        }),
      };
    case GET_ALERT_DURATION_TYPES_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getSensors(shipId) {
  return {
    types: [GET_SENSORS, GET_SENSORS_SUCCESS, GET_SENSORS_FAIL],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/ships/${shipId}/sensors`
      ),
  };
}

export function getSensor(sensorId) {
  return {
    types: [GET_SENSOR, GET_SENSOR_SUCCESS, GET_SENSOR_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/sensors/${sensorId}`),
  };
}

export function getSensorsTypes() {
  return {
    types: [
      GET_SENSORS_TYPES,
      GET_SENSORS_TYPES_SUCCESS,
      GET_SENSORS_TYPES_FAIL,
    ],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/sensors/types`),
  };
}

export function getShipsWithDecks() {
  return {
    types: [
      GET_SHIPS_WITH_DECKS,
      GET_SHIPS_WITH_DECKS_SUCCESS,
      GET_SHIPS_WITH_DECKS_FAIL,
    ],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/sensors/ships`),
  };
}

export function addSensor(data) {
  const { shipId } = data;

  return {
    types: [ADD_SENSOR, ADD_SENSOR_SUCCESS, ADD_SENSOR_FAIL],
    promise: client =>
      client.post(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/ships/${shipId}/sensors`,
        {
          data,
        }
      ),
  };
}

export function editSensor(data) {
  return {
    types: [EDIT_SENSOR, EDIT_SENSOR_SUCCESS, EDIT_SENSOR_FAIL],
    promise: client =>
      client.put(`${process.env.TOTOTHEO_API_ADMIN_URL}/sensors/${data.id}`, {
        data,
      }),
  };
}

export function getAlertDurationTypes() {
  return {
    types: [
      GET_ALERT_DURATION_TYPES,
      GET_ALERT_DURATION_TYPES_SUCCESS,
      GET_ALERT_DURATION_TYPES_FAIL,
    ],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/sensors/alert_duration_types`
      ),
  };
}
