const GET_USERS_AMOUNT = 'GET_USERS_AMOUNT';
const GET_USERS_AMOUNT_SUCCESS = 'GET_USERS_AMOUNT_SUCCESS';
const GET_USERS_AMOUNT_FAIL = 'GET_USERS_AMOUNT_FAIL';

const GET_SENSORS_AMOUNT = 'GET_SENSORS_AMOUNT';
const GET_SENSORS_AMOUNT_SUCCESS = 'GET_SENSORS_AMOUNT_SUCCESS';
const GET_SENSORS_AMOUNT_FAIL = 'GET_SENSORS_AMOUNT_FAIL';

const GET_SHIPS_AMOUNT = 'GET_SHIPS_AMOUNT';
const GET_SHIPS_AMOUNT_SUCCESS = 'GET_SHIPS_AMOUNT_SUCCESS';
const GET_SHIPS_AMOUNT_FAIL = 'GET_SHIPS_AMOUNT_FAIL';

const GET_SENSORS_ALERTS_AMOUNT = 'GET_SENSORS_ALERTS_AMOUNT';
const GET_SENSORS_ALERTS_AMOUNT_SUCCESS = 'GET_SENSORS_ALERTS_AMOUNT_SUCCESS';
const GET_SENSORS_ALERTS_AMOUNT_FAIL = 'GET_SENSORS_ALERTS_AMOUNT_FAIL';

const GET_USERS_ONLINE = 'GET_USERS_ONLINE';
const GET_USERS_ONLINE_SUCCESS = 'GET_USERS_ONLINE_SUCCESS';
const GET_USERS_ONLINE_FAIL = 'GET_USERS_ONLINE_FAIL';

const GET_SHIPS_ONLINE = 'GET_SHIPS_ONLINE';
const GET_SHIPS_ONLINE_SUCCESS = 'GET_SHIPS_ONLINE_SUCCESS';
const GET_SHIPS_ONLINE_FAIL = 'GET_SHIPS_ONLINE_FAIL';

const initialState = {
  dashboardError: {},
  loading: false,
  totalUsersAmount: null,
  increasedUsersPercentage: null,
  totalSensorsAmount: null,
  increasedSensorsPercentage: null,
  offlineSensorsAmount: null,
  totalShipsAmount: null,
  increasedShipsPercentage: null,
  totalSensorsAlertsAmount: null,
  increasedSensorsAlertsPercentage: null,
  usersOnlineData: null,
  shipsOnlineData: null,
};

export default function reducer() {
  return function(state = initialState, action = {}) {
    switch (action.type) {
      case GET_USERS_AMOUNT:
        return {
          ...state,
          loading: true,
        };
      case GET_USERS_AMOUNT_SUCCESS:
        return {
          ...state,
          totalUsersAmount: action.result.totalAmount,
          increasedUsersPercentage: action.result.increasedPercentage,
          loading: false,
        };
      case GET_USERS_AMOUNT_FAIL:
        return {
          ...state,
          loading: false,
        };

      case GET_SENSORS_AMOUNT:
        return {
          ...state,
          loading: true,
        };
      case GET_SENSORS_AMOUNT_SUCCESS:
        return {
          ...state,
          totalSensorsAmount: action.result.totalAmount,
          increasedSensorsPercentage: action.result.increasedPercentage,
          offlineSensorsAmount: action.result.offlineAmount,
          loading: false,
        };
      case GET_SENSORS_AMOUNT_FAIL:
        return {
          ...state,
          loading: false,
        };

      case GET_SHIPS_AMOUNT:
        return {
          ...state,
          loading: true,
        };
      case GET_SHIPS_AMOUNT_SUCCESS:
        return {
          ...state,
          totalShipsAmount: action.result.totalAmount,
          increasedShipsPercentage: action.result.increasedPercentage,
          loading: false,
        };
      case GET_SHIPS_AMOUNT_FAIL:
        return {
          ...state,
          loading: false,
        };

      case GET_SENSORS_ALERTS_AMOUNT:
        return {
          ...state,
          loading: true,
        };
      case GET_SENSORS_ALERTS_AMOUNT_SUCCESS:
        return {
          ...state,
          totalSensorsAlertsAmount: action.result.totalAmount,
          increasedSensorsAlertsPercentage: action.result.increasedPercentage,
          loading: false,
        };
      case GET_SENSORS_ALERTS_AMOUNT_FAIL:
        return {
          ...state,
          loading: false,
        };

      case GET_USERS_ONLINE:
        return {
          ...state,
          loading: true,
        };
      case GET_USERS_ONLINE_SUCCESS:
        const usersData = action.result.users;
        return {
          ...state,
          loading: false,
          usersOnlineData: usersData,
        };
      case GET_USERS_ONLINE_FAIL:
        return {
          ...state,
          loading: false,
        };
      case GET_SHIPS_ONLINE:
        return {
          ...state,
          loading: true,
        };
      case GET_SHIPS_ONLINE_SUCCESS:
        const shipsData = action.result.ships;
        return {
          ...state,
          loading: false,
          shipsOnlineData: shipsData,
        };
      case GET_SHIPS_ONLINE_FAIL:
        return {
          ...state,
          loading: false,
        };

      default:
        return state;
    }
  };
}

export function getUsersAmount() {
  return {
    types: [GET_USERS_AMOUNT, GET_USERS_AMOUNT_SUCCESS, GET_USERS_AMOUNT_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/users/amount`),
  };
}

export function getSensorsAmount() {
  return {
    types: [
      GET_SENSORS_AMOUNT,
      GET_SENSORS_AMOUNT_SUCCESS,
      GET_SENSORS_AMOUNT_FAIL,
    ],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/sensors/amount`),
  };
}

export function getShipsAmount() {
  return {
    types: [GET_SHIPS_AMOUNT, GET_SHIPS_AMOUNT_SUCCESS, GET_SHIPS_AMOUNT_FAIL],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/ships/amount`),
  };
}

export function getSensorsAlertsAmount() {
  return {
    types: [
      GET_SENSORS_ALERTS_AMOUNT,
      GET_SENSORS_ALERTS_AMOUNT_SUCCESS,
      GET_SENSORS_ALERTS_AMOUNT_FAIL,
    ],
    promise: client =>
      client.get(`${process.env.TOTOTHEO_API_ADMIN_URL}/sensors/alerts/amount`),
  };
}

export function getUsersOnline(step, interval) {
  return {
    types: [GET_USERS_ONLINE, GET_USERS_ONLINE_SUCCESS, GET_USERS_ONLINE_FAIL],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/users/online?step=${step}&interval=${interval}`
      ),
  };
}

export function getShipsOnline(step, interval) {
  return {
    types: [GET_SHIPS_ONLINE, GET_SHIPS_ONLINE_SUCCESS, GET_SHIPS_ONLINE_FAIL],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/ships/online?step=${step}&interval=${interval}`
      ),
  };
}
