const ADD_COMPANY = 'ADD_COMPANY';
const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';

const GET_COMPANIES_LIST = 'GET_COMPANIES_LIST';
const GET_COMPANIES_LIST_SUCCESS = 'GET_COMPANIES_LIST_SUCCESS';
const GET_COMPANIES_LIST_FAIL = 'GET_COMPANIES_LIST_FAIL';

const GET_COMPANY_LOGOS = 'GET_COMPANY_LOGOS';
const GET_COMPANY_LOGOS_SUCCESS = 'GET_COMPANY_LOGOS_SUCCESS';
const GET_COMPANY_LOGOS_FAIL = 'GET_COMPANY_LOGOS_FAIL';

const UPLOAD_COMPANY_IMAGE = 'UPLOAD_COMPANY_IMAGE';
const UPLOAD_COMPANY_IMAGE_SUCCESS = 'UPLOAD_COMPANY_IMAGE_SUCCESS';
const UPLOAD_COMPANY_IMAGE_FAIL = 'UPLOAD_COMPANY_IMAGE_FAIL';

const UPLOAD_COMPANY_LOGOS = 'UPLOAD_COMPANY_LOGOS';
const UPLOAD_COMPANY_LOGOS_SUCCESS = 'UPLOAD_COMPANY_LOGOS_SUCCESS';
const UPLOAD_COMPANY_LOGOS_FAIL = 'UPLOAD_COMPANY_LOGOS_FAIL';

const SEARCH_COMPANIES = 'SEARCH_COMPANIES';
const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS';
const SEARCH_COMPANIES_FAIL = 'SEARCH_COMPANIES_FAIL';

const UPDATE_COMPANY = 'UPDATE_COMPANY';
const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

const UPDATE_COMPANY_LOGOS = 'UPDATE_COMPANY_LOGOS';
const UPDATE_COMPANY_LOGOS_SUCCESS = 'UPDATE_COMPANY_LOGOS_SUCCESS';
const UPDATE_COMPANY_LOGOS_FAIL = 'UPDATE_COMPANY_LOGOS_FAIL';

const DELETE_COMPANY = 'DELETE_COMPANY';
const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';

const initialState = {
  companiesList: [],
  count: null,
  companiesError: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_COMPANY:
      return {
        ...state,
      };

    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
      };

    case ADD_COMPANY_FAIL:
      return {
        ...state,
      };

    case GET_COMPANIES_LIST:
      return {
        ...state,
      };

    case GET_COMPANIES_LIST_SUCCESS:
      return {
        ...state,
        companiesList: [...action.result.companies],
        count: action.result.count,
      };

    case GET_COMPANIES_LIST_FAIL:
      return {
        ...state,
      };

    case UPLOAD_COMPANY_IMAGE:
      return {
        ...state,
      };

    case UPLOAD_COMPANY_IMAGE_SUCCESS:
      return {
        ...state,
      };

    case UPLOAD_COMPANY_IMAGE_FAIL:
      return {
        ...state,
      };

    case SEARCH_COMPANIES:
      return {
        ...state,
      };

    case SEARCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesList: [...action.result.companies],
        count: action.result.count,
      };

    case SEARCH_COMPANIES_FAIL:
      return {
        ...state,
      };

    case UPDATE_COMPANY: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_COMPANY_SUCCESS: {
      return {
        ...state,
        loading: false,
        company: action.result.company,
      };
    }
    case UPDATE_COMPANY_FAIL: {
      return {
        ...state,
        loading: false,
        companiesError: action.error,
      };
    }

    case DELETE_COMPANY: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_COMPANY_SUCCESS: {
      const companyId = action.result.companyId;
      return {
        ...state,
        companiesList: state.companiesList.filter(
          company => company.id !== companyId
        ),
        loading: false,
      };
    }
    case DELETE_COMPANY_FAIL: {
      return {
        ...state,
        loading: false,
        companiesError: action.error,
      };
    }
    case GET_COMPANY_LOGOS: {
      return {
        ...state,
      };
    }
    case GET_COMPANY_LOGOS_SUCCESS: {
      return {
        ...state,
        companyLogos: action.result.companyLogos,
      };
    }
    case GET_COMPANY_LOGOS_FAIL: {
      return {
        ...state,
      };
    }
    case UPLOAD_COMPANY_LOGOS: {
      return {
        ...state,
      };
    }
    case UPLOAD_COMPANY_LOGOS_SUCCESS: {
      return {
        ...state,
      };
    }
    case UPLOAD_COMPANY_LOGOS_FAIL: {
      return {
        ...state,
      };
    }
    case UPDATE_COMPANY_LOGOS: {
      return {
        ...state,
      };
    }
    case UPDATE_COMPANY_LOGOS_SUCCESS: {
      return {
        ...state,
      };
    }
    case UPDATE_COMPANY_LOGOS_FAIL: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

export function addCompany(data) {
  return {
    types: [ADD_COMPANY, ADD_COMPANY_SUCCESS, ADD_COMPANY_FAIL],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_ADMIN_URL}/companies`, {
        data,
      }),
  };
}

export function uploadCompanyImage(file) {
  return {
    types: [
      UPLOAD_COMPANY_IMAGE,
      UPLOAD_COMPANY_IMAGE_SUCCESS,
      UPLOAD_COMPANY_IMAGE_FAIL,
    ],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_ADMIN_URL}/companies/image`, {
        attach: file,
      }),
  };
}

export function getCompaniesList(data) {
  const { limit, offset } = data;

  return {
    types: [
      GET_COMPANIES_LIST,
      GET_COMPANIES_LIST_SUCCESS,
      GET_COMPANIES_LIST_FAIL,
    ],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/companies?limit=${limit}&offset=${offset}&search=${data.search}`
      ),
  };
}

export function searchCompanies(data) {
  const { limit, offset, search } = data;

  return {
    types: [SEARCH_COMPANIES, SEARCH_COMPANIES_SUCCESS, SEARCH_COMPANIES_FAIL],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/companies?limit=${limit}&offset=${offset}&search=${search}`
      ),
  };
}

export function updateCompany(data, companyId) {
  console.log(data, 'data');
  return {
    types: [UPDATE_COMPANY, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAIL],
    promise: client =>
      client.put(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/companies/${companyId}`,
        {
          data,
        }
      ),
  };
}

export function deleteCompany(companyId) {
  return {
    types: [DELETE_COMPANY, DELETE_COMPANY_SUCCESS, DELETE_COMPANY_FAIL],
    promise: client =>
      client.del(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/companies/${companyId}`
      ),
  };
}

export function uploadCompanyLogos(file) {
  return {
    types: [
      UPLOAD_COMPANY_LOGOS,
      UPLOAD_COMPANY_LOGOS_SUCCESS,
      UPLOAD_COMPANY_LOGOS_FAIL,
    ],
    promise: client =>
      client.post(`${process.env.TOTOTHEO_API_ADMIN_URL}/companies/logos`, {
        attach: file,
      }),
  };
}

export function updateCompanyLogos(data, companyId) {
  console.log(data, companyId, 'data');
  return {
    types: [
      UPDATE_COMPANY_LOGOS,
      UPDATE_COMPANY_LOGOS_SUCCESS,
      UPDATE_COMPANY_LOGOS_FAIL,
    ],
    promise: client =>
      client.put(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/companies/${companyId}/logos`,
        {
          data,
        }
      ),
  };
}

export function getCompanyLogos(companyId) {
  return {
    types: [
      GET_COMPANY_LOGOS,
      GET_COMPANY_LOGOS_SUCCESS,
      GET_COMPANY_LOGOS_FAIL,
    ],
    promise: client =>
      client.get(
        `${process.env.TOTOTHEO_API_ADMIN_URL}/companies/${companyId}/logos`
      ),
  };
}
